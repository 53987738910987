<template>

  <div
    v-if="otherChargesTableDat.length"
    class="mb-1"
  >
    <h5
      class="font-weight-bolder "
    >
      {{ $t(OTHER_CHARGES) }}
    </h5>
    <l-table-list-collector
      :module-name="MODULE_NAME"
      :table-columns="tableColumnsOtherCharges"
      class="inventory-list-catalog-table"
      :is-searchable="false"
      :fetched-data="otherChargesTableDat"
      :has-footer="false"
    />
  </div>

</template>

<script>

import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import LTableListCollector from '@/views/components/LTableListCollector.vue'
import config from './config'
import mainConfig from '../../config'

export default {
  name: 'OtherChargesItems',
  components: {
    LTableListCollector,
  },
  computed: {
    order() {
      return this.$store.state[this.MODULE_NAME].order
    },
    otherChargesTableDat() {
      return this.$store.state[this.MODULE_NAME].orderItemsOtherChargesTable
    },
  },
  setup() {
    const {
      returnObjectForRender,
      tableColumnsCatalog,
      CAMERAS_LENSES,
      LIGHTING,
      OTHER_CHARGES,
      TOTALS,
      INTERNAL_INFORMATION,
      ACTIVITY_LOG,
      ORDER_NOTES,
      tableColumnsOtherCharges,
    } = config()

    const {
      MODULE_NAME,
    } = mainConfig()

    return {
      MODULE_NAME,
      CAMERAS_LENSES,
      LIGHTING,
      OTHER_CHARGES,
      TOTALS,
      INTERNAL_INFORMATION,
      ACTIVITY_LOG,
      ORDER_NOTES,
      getValueFromGivenObjectByKey,
      returnObjectForRender,
      tableColumnsCatalog,
      tableColumnsOtherCharges,
    }
  },
}
</script>
