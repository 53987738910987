<template>
  <b-row>
    <b-col
      class="form-wrapper"
    >
      <h4
        class="font-weight-bolder mb-1"
      >
        {{ $t(QUOTE_INFORMATION_TITLE) }}
      </h4>
      <b-row>
        <b-col
          v-for="(item, idx) in eventChildInformationObjectForRender"
          :key="idx"
          cols="12"
        >
          <b-row style="margin-bottom: 8px">
            <b-col cols="6">
              <b style="color: #646464">{{ item.label }}</b>
            </b-col>
            <b-col cols="6">
              <span
                style="color: #646464"
              >
                {{ fieldValue(order,item, 'MMMM d, yyyy hh:mm aaa') }}
              </span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import config from './config'
import mainConfig from '../../config'

export default {
  name: 'QuoteChildInformation',
  components: {
    BRow,
    BCol,
  },
  computed: {
    order() {
      return this.$store.state[this.MODULE_NAME].order
    },
  },
  setup() {
    const {
      QUOTE_INFORMATION_TITLE,
      eventChildInformationObjectForRender,
      fieldValue,
    } = config()

    const {
      MODULE_NAME,
    } = mainConfig()

    return {
      MODULE_NAME,
      QUOTE_INFORMATION_TITLE,
      getValueFromGivenObjectByKey,
      eventChildInformationObjectForRender,
      fieldValue,
    }
  },
}
</script>
