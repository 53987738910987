<template>
  <div class="form-wrapper">
    <div>
      <h3 class="font-weight-bolder mb-1">
        {{ $t(TOTALS_FORM_TITLE) }}
      </h3>
    </div>
    <b-row>
      <b-col md="6">
        <component
          :is="totalsFields['total_order_notes'].type"
          ref="total_order_notes_ref"
          v-model="totals['total_order_notes']"
          class="mb-0"
          v-bind="getProps('total_order_notes', totalsFields)"
        />
      </b-col>
      <b-col md="6">
        <!--    Order Details    -->
        <div
          v-if="!withoutDays"
          class="mb-2"
        >
          <h4 class="font-weight-bolder mb-1">
            {{ $t(TOTALS_FORM_ORDER_DETAILS_TITLE) }}
          </h4>
          <div class="pl-1">
            <div
              v-for="field in ['rental_duration', 'days_charged']"
              :key="field"
            >
              <div
                class="d-flex"
                style="gap: 12px"
              >
                <b
                  class="d-inline-block"
                  style="min-width: 150px"
                >
                  {{ $t(orderDetails[field].title) }}
                </b>
                {{ countDays(eventInformation.event_information_rental_start_dispatch, eventInformation.event_information_rental_end) }}
                <span>{{ $t(orderDetails[field].extraTitle) }}</span>
              </div>
            </div>
          </div>
        </div>

        <!--    Totals Computed table   -->
        <h4 class="font-weight-bolder mb-1">
          {{ $t(TOTALS_FORM_TOTALS_COMPUTED_TABLE_TITLE) }}
        </h4>
        <b-list-group>
          <b-list-group-item
            v-for="({ title,key}, index) in (calculateTotalProductPrice({ key: 'discount'}) > 0 ? totalsComputedTable : totalsComputedTable.filter(object => object.key !== 'discount') )"
            :key="index"
            :class="`totals-form-list-group-item ${(index + 1) % 2 === 0 ? 'totals-form-list-group-item-light': ''}`"
          >
            <div class="d-flex justify-content-between">
              <span>
                {{ $t(title) }}
              </span>
              <span>
                {{ formatNumberToCurrencyView(calculateTotalProductPrice({key})) }}
              </span>
            </div>
          </b-list-group-item>
          <b-list-group-item class="totals-form-list-group-item">
            <div class="d-flex justify-content-between">
              <b>
                {{ $t(TOTALS_FORM_TOTALS_COMPUTED_TABLE_TOTAL) }}
              </b>
              <b>
                {{ formatNumberToCurrencyView(totalPrice()) }}
              </b>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import { formatNumberToCurrencyView, sumTotalAmountOfGivenArrayByProperty } from '@core/utils/utils'

import config from '../config'

export default {
  name: 'Totals',
  components: {
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
  },
  props: {
    withoutDays: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    eventInformation() {
      return this.$store.state[this.MODULE_NAME].eventInformationForm
    },

    orderItemsOtherChargesArr() {
      const otherProducts = this.$store.state[this.MODULE_NAME].orderItemsOtherChargesTable
      return this.sumTotalAmountOfGivenArrayByProperty(
        otherProducts,
        'subtotal',
      )
    },
    orderItemsRentalArr() {
      const products = this.$store.state[this.MODULE_NAME].orderItemsRentalTable
      return this.sumTotalAmountOfGivenArrayByProperty(
        products,
        'subtotal',
      )
    },
    orderItemsSalesArr() {
      const sales = this.$store.state[this.MODULE_NAME].orderItemsSalesTable
      return this.sumTotalAmountOfGivenArrayByProperty(
        sales,
        'subtotal',
      )
    },

    totals() {
      return this.$store.state[this.MODULE_NAME].totalsForm
    },
  },
  methods: {
    allProductsDiscount() {
      const sales = this.sumTotalAmountOfGivenArrayByProperty(
        this.$store.state[this.MODULE_NAME].orderItemsSalesTable,
        'discountPrice',
      )
      const rent = this.sumTotalAmountOfGivenArrayByProperty(
        this.$store.state[this.MODULE_NAME].orderItemsRentalTable,
        'discountPrice',
      )
      const otherProducts = this.sumTotalAmountOfGivenArrayByProperty(
        this.$store.state[this.MODULE_NAME].orderItemsOtherChargesTable,
        'discountPrice',
      )
      return sales + rent + otherProducts
    },
    allProductsTaxes() {
      const sales = this.sumTotalAmountOfGivenArrayByProperty(
        this.$store.state[this.MODULE_NAME].orderItemsSalesTable,
        'taxes',
      )
      const rent = this.sumTotalAmountOfGivenArrayByProperty(
        this.$store.state[this.MODULE_NAME].orderItemsRentalTable,
        'taxes',
      )
      const otherProducts = this.sumTotalAmountOfGivenArrayByProperty(
        this.$store.state[this.MODULE_NAME].orderItemsOtherChargesTable,
        'tax',
      )
      return sales + rent + otherProducts
    },

    calculateTotalProductPrice({ key }) {
      const productsPrice = {
        rent: this.orderItemsRentalArr,
        sale_other_charges: this.orderItemsSalesArr + this.orderItemsOtherChargesArr,
        discount: this.allProductsDiscount(),
        taxes: this.allProductsTaxes(),
      }

      return productsPrice[key] ?? 0
    },

    totalPrice() {
      const total = [this.calculateTotalProductPrice({ key: 'rent' }), this.calculateTotalProductPrice({ key: 'sale_other_charges' }), this.calculateTotalProductPrice({ key: 'taxes' })]
      return total.reduce((a, b) => a + b, 0)
    },

    countDays(StartDate, EndDate) {
      if (!StartDate || !EndDate) {
        return 0
      }
      const startDay = new Date(StartDate)
      const endDay = new Date(EndDate)
      const oneDay = 1000 * 60 * 60 * 24

      // A day in UTC always lasts 24 hours
      const start = Date.UTC(endDay.getFullYear(), endDay.getMonth(), endDay.getDate())
      const end = Date.UTC(startDay.getFullYear(), startDay.getMonth(), startDay.getDate())
      if (!startDay && !endDay) {
        return 0
      }
      return (start - end) / oneDay
    },
  },
  setup() {
    const {
      getProps,
      MODULE_NAME,
      totalsFields,
      orderDetails,
      TOTALS_FORM_TITLE,
      totalsComputedTable,
      TOTALS_FORM_ORDER_DETAILS_TITLE,
      TOTALS_FORM_TOTALS_COMPUTED_TABLE_TITLE,
      TOTALS_FORM_TOTALS_COMPUTED_TABLE_TOTAL,
    } = config()

    return {
      getProps,
      MODULE_NAME,
      orderDetails,
      totalsFields,
      TOTALS_FORM_TITLE,
      totalsComputedTable,
      TOTALS_FORM_ORDER_DETAILS_TITLE,
      TOTALS_FORM_TOTALS_COMPUTED_TABLE_TITLE,
      TOTALS_FORM_TOTALS_COMPUTED_TABLE_TOTAL,
      sumTotalAmountOfGivenArrayByProperty,
      formatNumberToCurrencyView,
    }
  },
}
</script>
<style lang="scss">
.totals-form-list-group-item {
  padding: 5px 1.25rem !important;

  &:hover {
    background-color: transparent !important;
  }

  &-light {
    background-color: #FBFBFB !important;

    &:hover {
      background-color: #FBFBFB !important;
    }
  }

}
</style>
