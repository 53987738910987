<template>
  <div
    class="form-wrapper "
  >
    <h4
      class="font-weight-bolder mb-1"
    >
      {{ $t(INTERNAL_INFORMATION) }}
    </h4>
    <div>
      <h5
        class="font-weight-bolder "
      >
        {{ $t(ORDER_NOTES) }}
      </h5>
      <component
        :is="dispatchAndReturnFields[field].type"
        v-for="field in ['internal_shipping_notes']"
        :key="field"
        :ref="`${field}_ref`"
        v-model="dispatchAndReturnForm[field]"
        v-bind="getProps(field, dispatchAndReturnFields)"
        class="h-auto h5"
      />
    </div>
    <div>
      <h5
        class="font-weight-bolder "
      >
        {{ $t(ACTIVITY_LOG) }}
      </h5>
      <!--      <l-table-->
      <!--        ref="lTableRef"-->
      <!--        :module-name="MODULE_NAME"-->
      <!--        :table-columns="tableColumnsCatalog"-->
      <!--        class="inventory-list-catalog-table"-->
      <!--        :is-mobile-view-custom="true"-->
      <!--        :is-pagination-available="false"-->
      <!--        :is-searchable="false"-->
      <!--      />-->
    </div>

  </div>

</template>

<script>
import { getProps, getValueFromGivenObjectByKey } from '@core/utils/utils'
import LTable from '@/views/components/LTable/LTable.vue'
import config from './config'
import mainConfig from '../../config'

export default {
  name: 'InternalInformation',
  components: {
    LTable,
  },
  computed: {
    dispatchAndReturnForm() {
      return this.$store.state[this.MODULE_NAME].dispatchAndReturnForm
    },
  },
  methods: { getProps },
  setup() {
    const {
      tableColumnsCatalog,
      INTERNAL_INFORMATION,
      ACTIVITY_LOG,
      ORDER_NOTES,
      dispatchAndReturnFields,
    } = config()

    const {
      MODULE_NAME,
    } = mainConfig()

    return {
      MODULE_NAME,
      INTERNAL_INFORMATION,
      ACTIVITY_LOG,
      ORDER_NOTES,
      getValueFromGivenObjectByKey,
      tableColumnsCatalog,
      dispatchAndReturnFields,
    }
  },
}
</script>
