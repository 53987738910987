import { render, staticRenderFns } from "./RentalItems.vue?vue&type=template&id=3f51a6f6&scoped=true"
import script from "./RentalItems.vue?vue&type=script&lang=js"
export * from "./RentalItems.vue?vue&type=script&lang=js"
import style0 from "./RentalItems.vue?vue&type=style&index=0&id=3f51a6f6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f51a6f6",
  null
  
)

export default component.exports