<template>
  <div class="form-wrapper pl-0 pr-0">
    <b-row class="ml-0 mr-0">
      <b-col>
        <h3
          class="font-weight-bolder mb-1"
        >
          {{ $t(QUOTE_INFORMATION_TITLE) }}
        </h3>
        <b-row class="ml-0 mr-0">
          <b-col
            v-for="([key, value], idx) in Object.entries(quoteInformationObjectForRender)"
            :key="idx"
            cols="6"
          >
            <b-row style="margin-bottom: 8px">
              <b-col cols="12">
                <b
                  class="font-weight-bolder font-medium-5"
                >{{ key }}</b>
                <b-row>
                  <b-col
                    v-for="(item, index) in value"
                    :key="index"
                    cols="12 ml-1 mr-1"
                  >
                    <b-row>
                      <b-col cols="2">
                        <b>{{ item.label }}</b>
                      </b-col>
                      <b-col cols="10">
                        <span
                          style="color: #646464"
                        >
                          {{ fieldValue(order, item,'MMMM d, yyyy') }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import config from './config'
import mainConfig from '../../config'

export default {
  name: 'OrderInformation',
  components: {
    BRow,
    BCol,
  },
  computed: {
    order() {
      return this.$store.state[this.MODULE_NAME].order
    },
  },
  setup() {
    const {
      QUOTE_INFORMATION_TITLE,
      quoteInformationObjectForRender,
      fieldValue,
    } = config()

    const {
      MODULE_NAME,
    } = mainConfig()

    return {
      MODULE_NAME,
      QUOTE_INFORMATION_TITLE,
      getValueFromGivenObjectByKey,
      quoteInformationObjectForRender,
      fieldValue,
    }
  },
}
</script>
