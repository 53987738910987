import { render, staticRenderFns } from "./EventInformation.vue?vue&type=template&id=03dff1d0"
import script from "./EventInformation.vue?vue&type=script&lang=js"
export * from "./EventInformation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports