<template>
  <b-row>
    <b-col
      class="form-wrapper"
    >
      <h4
        class="font-weight-bolder mb-1"
      >
        {{ $t(EVENT_INFORMATION_TITLE) }}
      </h4>
      <b-row>
        <b-col
          v-for="({key , label}, idx) in eventInformationObjectForRender"
          :key="idx"
          cols="12"
        >
          <b-row style="margin-bottom: 8px">
            <b-col cols="6">
              <b style="color: #646464">{{ label }}</b>
            </b-col>
            <b-col cols="6">
              <span style="color: #646464">{{ getValueFromGivenObjectByKey(order, key ) }}</span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import config from './config'
import mainConfig from '../../config'

export default {
  name: 'EventInformation',
  components: {
    BRow,
    BCol,
  },
  computed: {
    order() {
      return this.$store.state[this.MODULE_NAME].order
    },
  },
  setup() {
    const {
      EVENT_INFORMATION_TITLE,
      eventInformationObjectForRender,
    } = config()

    const {
      MODULE_NAME,
    } = mainConfig()

    return {
      MODULE_NAME,
      EVENT_INFORMATION_TITLE,
      getValueFromGivenObjectByKey,
      eventInformationObjectForRender,
    }
  },
}
</script>
