<template>
  <div
    v-if="Object.keys(rentalTableData).length"
    class="mb-1"
  >
    <h4
      class="font-weight-bolder mb-1"
    >
      {{ $t(RENTAL_ITEMS) }}
    </h4>
    <div
      v-for="[key,value,index] in rentalTableData"
      :key="index"
    >

      <h5
        class="font-weight-bolder "
      >
        {{ value[0].category.map(c => c.name).join(' + ') }}
      </h5>

      <l-table-list-collector
        :module-name="MODULE_NAME"
        :table-columns="tableColumnsCatalog"
        :is-searchable="false"
        :fetched-data="value"
        class="inventory-list-catalog-table"
        :has-footer="false"
      >
        <template #cell(sku)="{data }">
          <div class="d-flex flex-column">
            <span class="ml-1">{{ data.item.name }}</span>
            <span class="ml-1">{{ data.item.notes }}</span>
          </div>
        </template>
        <template #cell(name)="{data }">
          <div>
            <feather-icon
              id="ready"
              :icon="getIcon(data)"
              :class="getClass(data)"
              size="16"
            />
            <span class="ml-1">{{ data.item.name }}</span>
          </div>
        </template>
      </l-table-list-collector>

    </div>
  </div>

</template>

<script>

import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import LTableListCollector from '@/views/components/LTableListCollector.vue'
import config from './config'
import mainConfig from '../../config'

export default {
  name: 'RentalItems',
  components: {
    LTableListCollector,
  },
  computed: {
    order() {
      return this.$store.state[this.MODULE_NAME].order
    },
    rentalTableData() {
      const products = this.$store.state[this.MODULE_NAME].orderItemsRentalTable
      return Object.entries(Object.groupBy(products, product => product.category))
    },
  },
  methods: {
    getIcon(data) {
      return this.productStatus.find(item => item.status === Number(data.item.status))?.icon ?? ''
    },
    getClass(data) {
      return this.productStatus.find(item => item.status === Number(data.item.status))?.class ?? ''
    },
  },
  setup() {
    const {
      RENTAL_ITEMS,
      returnObjectForRender,
      tableColumnsCatalog,
      CAMERAS_LENSES,
      LIGHTING,
      TOTALS,
      INTERNAL_INFORMATION,
      ACTIVITY_LOG,
      ORDER_NOTES,
      productStatus,
    } = config()

    const {
      MODULE_NAME,
    } = mainConfig()

    return {
      MODULE_NAME,
      RENTAL_ITEMS,
      CAMERAS_LENSES,
      LIGHTING,
      TOTALS,
      INTERNAL_INFORMATION,
      ACTIVITY_LOG,
      ORDER_NOTES,
      getValueFromGivenObjectByKey,
      returnObjectForRender,
      tableColumnsCatalog,
      productStatus,
    }
  },
}
</script>
<style scoped lang="scss">
.l-tableList-collector .table-responsive {
  background-color: red !important;
}
</style>
