<template>
  <div
    v-if="saleTableData.length"
    class="mb-1"
  >
    <h5
      class="font-weight-bolder "
    >
      {{ $t(SALES) }}
    </h5>
    <l-table-list-collector
      :module-name="MODULE_NAME"
      :table-columns="tableColumnsCatalog"
      class="inventory-list-catalog-table"
      :is-searchable="false"
      :fetched-data="saleTableData"
      :has-footer="false"
    >
      <template #cell(name)="{ data }">
        <div>
          <feather-icon
            id="ready"
            :icon="getIcon(data)"
            :class="getClass(data)"
            size="16"
          />
          <span class="ml-1">{{ data.item.name }}</span>
        </div>
      </template>
    </l-table-list-collector>
  </div>

</template>

<script>

import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import LTableListCollector from '@/views/components/LTableListCollector.vue'
import config from './config'
import mainConfig from '../../config'

export default {
  name: 'SaleItems',
  components: {
    LTableListCollector,
  },
  computed: {
    order() {
      return this.$store.state[this.MODULE_NAME].order
    },
    saleTableData() {
      return this.$store.state[this.MODULE_NAME].orderItemsSalesTable
    },
  },
  methods: {
    getIcon(data) {
      return this.productStatus.find(item => item.status === Number(data.item.status))?.icon ?? ''
    },
    getClass(data) {
      return this.productStatus.find(item => item.status === Number(data.item.status))?.class ?? ''
    },
  },
  setup() {
    const {
      returnObjectForRender,
      tableColumnsCatalog,
      CAMERAS_LENSES,
      LIGHTING,
      SALES,
      TOTALS,
      INTERNAL_INFORMATION,
      ACTIVITY_LOG,
      ORDER_NOTES,
      productStatus,
    } = config()

    const {
      MODULE_NAME,
    } = mainConfig()

    return {
      MODULE_NAME,
      CAMERAS_LENSES,
      LIGHTING,
      SALES,
      TOTALS,
      INTERNAL_INFORMATION,
      ACTIVITY_LOG,
      ORDER_NOTES,
      getValueFromGivenObjectByKey,
      returnObjectForRender,
      tableColumnsCatalog,
      productStatus,
    }
  },
}
</script>
