<template>
  <b-row>
    <b-col
      class="form-wrapper "
    >
      <h4
        class="font-weight-bolder mb-1"
      >
        {{ $t(RETURN) }}
      </h4>
      <b-row>
        <b-col
          v-for="(item, idx) in returnObjectForRender"
          :key="idx"
          cols="12"
        >
          <b-row style="margin-bottom: 8px">
            <b-col cols="6">
              <b style="color: #646464">{{ item.label }}</b>
            </b-col>
            <b-col
              v-if="idx === 0"
              cols="6"
            >
              <feather-icon icon="LCalendarsIcon" />

              <span
                style="color: #646464"
              >
                <b>{{ fieldValue(order,item,'MMMM d, yyyy hh:mm aaa') }}</b>
              </span>
            </b-col>
            <b-col
              v-if="idx !== 0"
              cols="6"
            >
              <span
                style="color: #646464"
              >
                {{ fieldValue(order,item,'MMMM d, yyyy hh:mm aaa') }}
              </span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>

</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import config from './config'
import mainConfig from '../../config'

export default {
  name: 'EventInformation',
  components: {
    BRow,
    BCol,
  },
  computed: {
    order() {
      return this.$store.state[this.MODULE_NAME].order
    },
  },
  setup() {
    const {
      RETURN,
      returnObjectForRender,
      fieldValue,
    } = config()

    const {
      MODULE_NAME,
    } = mainConfig()

    return {
      MODULE_NAME,
      RETURN,
      getValueFromGivenObjectByKey,
      returnObjectForRender,
      fieldValue,
    }
  },
}
</script>
